import { BaseResponse } from '~/sdk/shared';
import { ERPName } from '~/config/erp';
import API from '~/sdk/client'

export type Entity = {
  displayName: ERPName
  isSyncing: boolean
  images: {
    logo: {
      large: string
      thumb: string
    }
  }
  settings: {
    automatedBookkeeping: boolean
  }
  syncedAt: string
  createdAt: string
}

export async function get(): Promise<BaseResponse<Entity[]>> {
  return await API.get('internal/v1/company/providers')
}

import { BaseExchange, BaseFilterRequest, BaseResponse, buildRequestQuery, PaginatedBaseResponse } from '~/sdk/shared'
import { AxiosResponse } from 'axios'
import API from '~/sdk/client'

interface GetCustomersFilterQuery {
  search: string
}

interface Customer {
  id: number
  name: string
  identityNumber: string
}

interface CreateCustomerRequest {
  name: string
  foreignIdentityNumber: boolean
  identityNumber: string
  email?: string
  phone?: string
  address1?: string
  address2?: string
  zipCode?: string
  city?: string
  countryCode?: string
}

export interface CustomerEntity {
  id: number,
  name: string,
  identityNumber: number,
  email: string
  phone: string
  address1: string
  address2: string
  zipCode: string
  city: string
  countryCode: string
  country: string
}

export interface UpdateCustomer {
  email: string | null
  phone: string | null
  address1: string | null
  address2:	string | null
  city: string | null
  countryCode?: string | null
  country: string | null
}

export async function get(request?: BaseFilterRequest<GetCustomersFilterQuery>): Promise<AxiosResponse<PaginatedBaseResponse<Customer>>> {
  return await API.get<PaginatedBaseResponse<Customer>>(`internal/v2/company/customers${buildRequestQuery(request)}`)
}

export async function create(payload: BaseExchange<CreateCustomerRequest>): Promise<BaseResponse<Customer>> {
  return await API.post('internal/v2/company/customers', payload)
}

export async function show(id: string): Promise<BaseResponse<CustomerEntity>> {
  return await API.get(`internal/v2/company/customers/${id}`)
}

export async function put(id: string, payload: BaseExchange<UpdateCustomer>): Promise<BaseResponse<CustomerEntity>> {
  return await API.put(`internal/v2/company/customers/${id}`, payload)
}
